import React, { useEffect, useState } from 'react';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { message } from 'antd';
import { fetchsubSubCategory, updatesubSubCategory, getByIdsubSubCategory } from '../../store/subSubCategorySlice';
import { fetchsubCategory } from '../../store/subCategorySlice';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../Loader';
import axios from 'axios';

const UpdateSubSubCategory = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { data: subSubCategory } = useSelector((state) => state.subSubCategory);
    const { data: subCategory } = useSelector((state) => state.subCategory);

    const [file, setFile] = useState(null);
    const [subsubcategoryname, setSubsubcategoryname] = useState('');
    const [subcategoryId, setSubcategoryId] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchsubCategory());
                await dispatch(fetchsubSubCategory());
                await dispatch(getByIdsubSubCategory(id));
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [dispatch, id]);

    useEffect(() => {
        if (subSubCategory) {
            setSubsubcategoryname(subSubCategory.name || '');
            setSubcategoryId(subSubCategory.subCategoryId || '');
            setFile(subSubCategory.image || null);
        }
    }, [subSubCategory]);

    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                $('#allOrder').DataTable({
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                });
            }, 1000);
        }
    }, [loading]);

    const handleUpload = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        try {
            const response = await axios.post('https://olx.ashhkaro.com/common/auth/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                },
            });

            setFile(response.data.data);
            message.success('Image uploaded successfully!');
        } catch (error) {
            console.error('Upload error:', error);
            message.error('Failed to upload image.');
        }
    };

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        if (!subsubcategoryname) {
            message.error('Please enter a name.');
            return;
        }
        if (!subcategoryId) {
            message.error('Please select a sub category.');
            return;
        }
        const payload = {
            name: subsubcategoryname || subSubCategory.name,
            subCategoryId: subcategoryId || subSubCategory.subCategoryId,
            image: file || subSubCategory.image,
        };
        try {
            dispatch(updatesubSubCategory(id, payload));
            message.success('Sub Sub Category updated successfully!');
           setLoading(false)
        } catch (error) {
            message.error('Failed to update Sub Sub Category.');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px' }}>
            <div className="content container-fluid mt-5">
                <div>
                    <h2 className="h1 mb-0 d-flex gap-10">
                        <img src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png" alt="" />
                        Sub Sub Category Setup
                    </h2>

                    <form onSubmit={handleSubmit}>
                        <div className="card mt-2 p-4">
                            <div className="row">
                                <div className="col-12 form-group lang_form" id="en-form">
                                    <label className="title-color" htmlFor="name">
                                        Sub Sub Category Name<span className="text-danger">*</span> (EN)
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        className="form-control"
                                        placeholder="New Sub Sub Category"
                                        required
                                        value={subsubcategoryname}
                                        onChange={(e) => setSubsubcategoryname(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="title-color">
                                            Sub Category
                                            <span className="text-danger">*</span>
                                        </label>
                                        <select
                                            name="subCategoryId"
                                            id="subCategoryId"
                                            className="form-control"
                                            required
                                            value={subcategoryId}
                                            onChange={(e) => setSubcategoryId(e.target.value)}
                                        >
                                            <option value="" disabled>
                                                Select Sub Category
                                            </option>
                                            {subCategory.map((cat) => (
                                                <option key={cat._id} value={cat._id}>
                                                    {cat.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label className="title-color">Sub Sub Category Image</label>
                                <div className="custom-file text-left">
                                    {file ? (
                                        <img style={{ display: 'block' }} width={100} src={file} alt='Sub Sub Category' />
                                    ) : (
                                        <img
                                            className="upload-img-view"
                                            id="viewer"
                                            src={subSubCategory.image}
                                            alt="Sub Sub Category"
                                        />
                                    )}
                                </div>
                                <div className="from_part_2" style={{ marginTop: '20px' }}>
                                    <label className="custom-file-label" htmlFor="image">
                                        Choose File
                                    </label>
                                    <div className="custom-file text-left">
                                        <input
                                            type="file"
                                            name="image"
                                            id="image"
                                            className="custom-file-input"
                                            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                            onChange={handleUpload}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap gap-2 justify-content-end mt-4">
                                <button type="reset" id="reset" className="btn btn-secondary">
                                    Reset
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateSubSubCategory;
