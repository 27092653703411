import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from 'jquery';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, message } from 'antd';
import { fetchcategory, updatecategory, getByIdcategory } from '../../store/categorySlice';
import Loader from '../Loader';

const Category = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { data } = useSelector((state) => state.category);
    const [file, setFile] = useState(null);
    const [categoryname, setCategoryname] = useState('');
    const [uploadStatus, setUploadStatus] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(fetchcategory());
        dispatch(getByIdcategory(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (data) {
            setCategoryname(data.name || '');
            setFile(data.image || null);
        }
    }, [data]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const payload = {
            name: categoryname || data?.name,
            image: file || data?.image
        };

        try {
            await dispatch(updatecategory(id, payload));
            message.success('Category updated successfully!');
        } catch (error) {
            console.error('Error updating category:', error);
            message.error('Failed to update category.');
        } finally {
            setLoading(false);
        }
    };

    const handleUpload = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        try {
            const response = await axios.post('https://olx.ashhkaro.com/common/auth/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadStatus(`Upload is ${percentCompleted}% done.`);
                }
            });

            setFile(response.data.data);
            message.success('Image uploaded successfully!');
        } catch (error) {
            console.error('Upload error:', error);
            message.error('Failed to upload image.');
        }
    };

    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <h2 className="h1 mb-0 d-flex gap-10">
                    <img src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png" alt="" />
                    Category Setup
                </h2>

                <form onSubmit={handleSubmit}>
                    <div className="card mt-2 p-4">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group lang_form" id="en-form">
                                    <label className="title-color">
                                        Category Name<span className="text-danger">*</span> (EN)
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        className="form-control"
                                        placeholder="New Category"
                                        required
                                        value={categoryname}
                                        onChange={(e) => setCategoryname(e.target.value)}
                                    />
                                </div>

                                <div>
                                    <label className="title-color">Category Image</label>
                                    <div className="custom-file text-left">
                                        {file ? (
                                            <img style={{ display: 'block' }} width={100} src={file} alt='Category' />
                                        ) : (
                                            <img
                                                className="upload-img-view"
                                                id="viewer"
                                                src={data?.image || ''}
                                                alt="Category"
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="from_part_2" style={{ marginTop: '100px' }}>
                                    <label className="custom-file-label" htmlFor="customFileEg1">
                                        Choose File
                                    </label>
                                    <div className="custom-file text-left">
                                        <input
                                            type="file"
                                            name="image"
                                            id="image"
                                            className="custom-file-input"
                                            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                            onChange={handleUpload}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <button type="reset" id="reset" className="btn btn-secondary">
                                Reset
                            </button>
                            <button type="submit" className="btn btn-primary">
                                {loading ? <Loader /> : 'Submit'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Category;
