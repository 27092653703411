import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const categorySlice=createSlice({
    name: "category",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setcategory(state,action){
            state.data=action.payload
        },
        getcategory(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setcategory,setStatus,getcategory}=categorySlice.actions
export default categorySlice.reducer




// thunk

export function fetchcategory(){
    return async function fetchcategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`https://olx.ashhkaro.com/common/category/getAllCategories`).then(
                (resp) => {
                    dispatch(setcategory(resp?.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createcategory(param){
    return async function createcategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
       
        try {
            const response = await axios.post('https://olx.ashhkaro.com/common/category/createCategories', param, {
                headers: {
                    'Content-Type': 'application/json' 
                },
                
            });
            console.log(response.data)
            if (response.data?.status === true) {
               await message.success("category Created")
                 window.location.reload(true)
                        }
            console.log(response.data)
            
        } catch (error) {
            
            console.log(error);
            dispatch(setStatus(STATUS.ERROR))
            message.error("Category ALready exists")
        }
       
    }
}



export function updatecategory(id,param){
    return async function updatecategoryThunk(dispatch, getState){
       console.log(param)

        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.patch(`https://olx.ashhkaro.com/common/category/editCategories/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data) {
                    message.success("category Updated")
                    window.location.href=window.location.origin+"/#/category"
                    
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            message.error('Something went wrong')
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatecategorystatus(param){
    return async function updatecategoryThunk(dispatch, getState){
       
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.patch(`https://olx.ashhkaro.com/common/category/editCategories/${param.id}`,{status:param.status}).then(res=>{
                console.log(res.data)
                if (res.data) {
                    message.success("category Updated")
                    window.location.href=window.location.origin+"/#/category"
                    
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            message.error('Something went wrong')
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
export function updatebussinessstatus(param){
    console.log(param)
    return async function updatecategoryThunk(dispatch, getState){
       
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.patch(`https://olx.ashhkaro.com/common/business/businesses/${param.id}/status/`,{status:param.status}).then(res=>{
                if (res.data) {
                    message.success("Bussiness Status Updated")
                    window.location.reload(true)
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            message.error('Something went wrong')
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}

export function deletecategory(id){
    return async function deletecategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`https://olx.ashhkaro.com/common/category/delCategories/${id}`).then(res=>{
                console.log(res.data)
                if(res.data){
                message.success('Category deleted successfully')
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function getByIdcategory(id){
    console.log(id)
    return async function getByIdcategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`https://olx.ashhkaro.com/common/category/getCategories/${id}`).then(
                (resp) => {
                    dispatch(getcategory(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
