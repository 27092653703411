import React, { useEffect, useState } from 'react';
import 'jquery/dist/jquery.min.js';
// DataTables and related imports
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, message } from 'antd';
import { updatesubCategory, fetchsubCategory, getByIdsubCategory } from '../../store/subCategorySlice';
import { fetchcategory, getByIdcategory } from '../../store/categorySlice';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';
import axios from 'axios';

const UpdateSubCate = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { data: subCategory } = useSelector((state) => state.subCategory);
    const { data: category } = useSelector((state) => state.category);
    const [loading, setLoading] = useState(false);
    const [categoryid, setCategoryid] = useState('');
    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [subCategoryName, setSubCategoryName] = useState('');

    useEffect(() => {
        dispatch(getByIdsubCategory(id));
        dispatch(fetchcategory());
    }, [dispatch, id]);

    useEffect(() => {
        if (subCategory) {
            setSubCategoryName(subCategory.name || '');
            setCategoryid(subCategory.categoryId || '');
            setFile(subCategory.image || null);
        }
    }, [subCategory]);

    useEffect(() => {
        if (category && category.length > 0) {
            setCategoryid(subCategory ? subCategory.categoryId : category[0]._id);
        }
    }, [category, subCategory]);

    useEffect(() => {
        if ($) {
            setTimeout(() => {
                $('#allOrder').DataTable({
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                });
            }, 1000);
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const payload = {
            name: subCategoryName || subCategory.name,
            categoryId: categoryid || subCategory.categoryId,
            image: file || subCategory.image,
        };

        try {
            await dispatch(updatesubCategory({ id, ...payload }));
            message.success('Sub category updated successfully!');
        } catch (error) {
            console.error('Error updating sub category:', error);
            message.error('Failed to update sub category.');
        } finally {
            setLoading(false);
        }
    };

    const handleUpload = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        try {
            const response = await axios.post('https://olx.ashhkaro.com/common/auth/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadStatus(`Upload is ${percentCompleted}% done.`);
                },
            });

            setFile(response.data.data);
            message.success('Image uploaded successfully!');
        } catch (error) {
            console.error('Upload error:', error);
            message.error('Failed to upload image.');
        }
    };

    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px' }}>
            <div className="content container-fluid mt-5">
                <h2 className="h1 mb-0 d-flex gap-10">
                    <img src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png" alt="" />
                    Sub Category Setup
                </h2>

                <form onSubmit={handleSubmit}>
                    <div className="card mt-2 p-4">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group lang_form" id="en-form">
                                    <label className="title-color">
                                        Sub Category Name<span className="text-danger">*</span> (EN)
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        className="form-control"
                                        placeholder="New Sub Category"
                                        required
                                        value={subCategoryName}
                                        onChange={(e) => setSubCategoryName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="title-color">
                                        Main Category<span className="text-danger">*</span>
                                    </label>
                                    <select
                                        name="categoryId"
                                        id="categoryId"
                                        className="form-control"
                                        required
                                        value={categoryid}
                                        onChange={(e) => setCategoryid(e.target.value)}
                                    >
                                        <option value="" disabled>Select a category</option>
                                        {category.map((cat) => (
                                            <option key={cat._id} value={cat._id}>
                                                {cat.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-4">
                                <label className="title-color">Sub Category Image</label>
                                <div className="custom-file text-left">
                                    {file ? (
                                        <img style={{ display: 'block' }} width={100} src={file} alt='Sub Category' />
                                    ) : (
                                        <img
                                            className="upload-img-view"
                                            id="viewer"
                                            src={subCategory.image}
                                            alt="Sub Category"
                                        />
                                    )}
                                </div>
                                <div className="from_part_2" style={{ marginTop: '60px' }}>
                                    <label className="custom-file-label" htmlFor="customFileEg1">
                                        Choose File
                                    </label>
                                    <div className="custom-file text-left">
                                        <input
                                            type="file"
                                            name="image"
                                            id="image"
                                            className="custom-file-input"
                                            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                            onChange={handleUpload}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-wrap gap-2 justify-content-end mt-4">
                            <button type="reset" id="reset" className="btn btn-secondary">
                                Reset
                            </button>
                            <button type="submit" className="btn btn-primary">
                                {loading ? <Loader /> : 'Submit'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UpdateSubCate;
