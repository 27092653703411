import React, { useEffect, useState } from 'react';
import 'jquery/dist/jquery.min.js';
// Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, message } from 'antd';
import { createflash, deleteflash, fetchflash, updateflash } from '../../store/flashSlice';
import { getSingByIdflash } from '../../store/flashDealProductSlice';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const FlashDeal = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data: flash, status } = useSelector((state) => state?.flash);
    const { data: flashDealProduct, statusF } = useSelector((state) => state?.flashDealProduct);

    useEffect(() => {
        dispatch(fetchflash());
    }, []);

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable({
                pagingType: 'full_numbers',
                pageLength: 25,
                processing: true,
                bDestroy: true,
            });
        }, 1000);
    });

    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deleteflash(id));
            }
        });
    };

    const handleCategory = (values) => {
       
     if (values.description.value === '') {
            message.error('Must enter Description');
        }
      if(file ==null){
        message.error('Must upload Image');
      }  
      if(values.discountPercentage.value ==''){
        message.error('Must enter discountpercentage');
      }  
      if(values.code.value == ''){
        message.error('Must enter Coupon code');
      }
      if(values.usageLimit.value == ''){
        message.error('Must enter usageLimit');
      }
      if(values.cost.value == ''){
        message.error('Must enter Coupon cost');
      }
      if(values.status.value == ''){
        message.error('Must Select status');
      }
        else {
            dispatch(createflash({code:values.code.value,discountPercentage:Number(values.discountPercentage.value),description:values.description.value,image:file,status:values.status.value,usageLimit:Number(values.usageLimit.value),cost:Number(values.cost.value)}));

        }
    };

    const [file, setFile] = useState(null);

    const handleUpload = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        try {
            const response = await axios.post('https://olx.ashhkaro.com/common/auth/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(`Upload is ${percentCompleted}% done.`);
                }
            });

            setFile(response.data.data);
            alert('Image uploaded successfully!');
        } catch (error) {
            console.error('Upload error:', error);
            alert('Failed to upload image.');
        }
    };

    const [randomCode, setRandomCode] = useState('');

    useEffect(() => {
        generateRandomCode();
    }, []);

    const generateRandomCode = () => {
        const length = 8;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setRandomCode(result);
    };

    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>
            <div className="content container-fluid mt-5">
                <div>
                    <h2 className="h1 mb-0 text-capitalize d-flex gap-2">
                        <img
                            width={20}
                            src="https://6valley.6amtech.com/public/assets/back-end/img/flash_deal.png"
                            alt=""
                        />
                        Coupons
                    </h2>
                    <form onSubmit={(e) => { e.preventDefault(); handleCategory(e.target) }}>
                        <div className="card mt-2 p-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className=" lang_form" id="en-form">
                                        <div className="form-group">
                                            <label
                                                htmlFor="name"
                                                className="title-color font-weight-medium text-capitalize"
                                            >
                                             Coupons code
                                            </label>
                                            <input
                                                type="text"
                                                name="code"
                                                className="form-control"
                                                id="code"
                                                placeholder="Ex : LUX"
                                                required=""
                                                defaultValue={randomCode}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                            <label
                                                htmlFor="name"
                                                className="title-color font-weight-medium text-capitalize"
                                            >
                                             Discount percentage
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="discountPercentage"
                                                name="discountPercentage"
                                                placeholder="discount %"
                                                required=""
                                               
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="name"
                                                className="title-color font-weight-medium text-capitalize"
                                            >
                                            Cost
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="cost"
                                                name="cost"
                                                placeholder="Cost"
                                                required=""
                                               
                                            />
                                        </div>
                                  
                                    <div className="form-group">
                                        <label
                                            htmlFor="name"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                           Status
                                        </label>
                                        <select
                                            id="status"
                                            name="status"
                                            className="form-control"
                                            required=""
                                        >
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor="name"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                            Description
                                        </label>
                                        <input
                                            type="text"
                                            name="description"
                                            className="form-control"
                                            id="description"
                                            placeholder="Enter description"
                                            required=""
                                        />
                                    </div>
                                    <div className="form-group">
                                            <label
                                                htmlFor="name"
                                                className="title-color font-weight-medium text-capitalize"
                                            >
                                             Usage Limit
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="usageLimit"
                                                name="usageLimit"
                                                placeholder="Using Limit"
                                                required=""
                                               
                                            />
                                        </div>
                                </div>
                                
                                <div className="col-lg-6">
                                <div className="custom-file text-left">
                                <label
                                            htmlFor="name"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                            Upload image
                                        </label>                                          {file? <img style={{display:'block'}} width={100} src={file} alt=''/>:<div>
                                          <img
                                                className="upload-img-view"
                                                id="viewer"
                                                src="https://6valley.6amtech.com/public/assets/back-end/img/900x400/img1.jpg"
                                                alt="image"
                                            />
                                            </div>}
                                        </div>
                                    <div style={{marginTop:'100px'}} className="form-group">
                                       
                                        <div className="custom-file text-left">
                                            <input
                                                type="file"
                                                name="image"
                                                id="image"
                                                className="custom-file-input"
                                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                                onChange={(e)=>handleUpload(e)}
                                            />
                                            <label
                                                className="custom-file-label text-capitalize"
                                                htmlFor="customFileUpload"
                                            >
                                                Choose file
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                <button type="reset" id="reset" className="btn btn-secondary">
                                    Reset
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                    {/* Flash deal table */}
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <div className="card p-3">
                                <div className="px-3 py-4">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                                            <h5 className="mb-0 text-capitalize d-flex gap-2">
                                               Coupons
                                                <span className="badge badge-soft-dark radius-50 fz-12">4</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table
                                        id='allOrder'
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th>SL</th>
                                                <th>Code</th>
                                                <th>Usage Limit</th>
                                                <th>Status</th>
                                                <th>Discount</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {flash?.length > 0 ? flash && flash?.map((i, index) => {
                                                return (<tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <span className="font-weight-semibold">{i?.code}</span>
                                                    </td>
                                                    <td>{i?.usageLimit}</td>
                                                    <td>
                                                        <span className="badge badge-soft-danger">{i?.status} </span>
                                                    </td>
                                                    
                                                    <td>
                                                       {i?.discountPercentage}
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="d-flex align-items-center justify-content-center gap-3">
                                                            <button
                                                                title="Delete"
                                                                className="btn btn-outline-danger btn-sm"
                                                                onClick={() => { onDelete(i?._id) }}
                                                            >
                                                                <i className="bi bi-trash" />
                                                            </button>
                                                            <a
                                                                className="h-30 d-flex gap-2 align-items-center btn btn-soft-info btn-sm border-info"
                                                                onClick={() => navigate(`/updateCoupon/${i?._id}`)}
                                                            >
                                                              
                                                              <i className="bi bi-pencil" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>)
                                            }) : ''}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-responsive mt-4">
                                    <div className="px-4 d-flex justify-content-lg-end"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlashDeal;
