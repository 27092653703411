import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, message } from 'antd';
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import {  useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import axios from 'axios';
import { createsubSubCategory, deletesubSubCategory, fetchsubSubCategory,updatesubSubCategoryStatus } from '../../store/subSubCategorySlice';
import { fetchsubCategory } from '../../store/subCategorySlice';

const SubSubCategory = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { data: subSubCategory } = useSelector((state) => state.subSubCategory);
    const { data: subCategory } = useSelector((state) => state.subCategory);

    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [subsubcategoryname, setSubsubcategoryname] = useState('');
    const [subcategory_id, setSubcategoryId] = useState('');

    const [loading, setLoading] = useState(false);
    const [checkedStates, setCheckedStates] = useState([]);

    useEffect(() => {
        dispatch(fetchsubCategory());
        dispatch(fetchsubSubCategory());
    }, [dispatch]);

    useEffect(() => {
        // Initialize checkedStates based on subSubCategory status
        if (subSubCategory && subSubCategory.length > 0) {
            const initialCheckedStates = subSubCategory.map((item) => item.status);
            setCheckedStates(initialCheckedStates);
        }
    }, [subSubCategory]);

    useEffect(() => {
        $(document).ready(function () {
            setTimeout(function () {
                $('#allOrder').DataTable({
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true
                });
            }, 1000);
        });
    }, []);

    const toggleSwitch = async (index, subSubCategoryId, currentStatus) => {
        const newStatus = !checkedStates[index]; // Toggle the current state
        console.log(newStatus)
        try {
            // Update UI optimistically
            setCheckedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = newStatus;
                return newStates;
            });

            // Update backend via Redux action
            await dispatch(updatesubSubCategoryStatus({ id: subSubCategoryId, status: newStatus }));


            // Handle success (if needed)
            console.log('Status updated successfully');
        } catch (error) {
            // Handle error (rollback UI changes if needed)
            console.error('Error updating status:', error);

            // Revert UI changes on failure
            setCheckedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = currentStatus; // Revert to previous status
                return newStates;
            });

            // Optionally show error message
            message.error('Failed to update sub subcategory status.');
        }
    };

    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deletesubSubCategory(id));
            }
        });
    };

    const handleUpload = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        try {
            const response = await axios.post('https://olx.ashhkaro.com/common/auth/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadStatus(`Upload is ${percentCompleted}% done.`);
                }
            });

            setFile(response.data.data);
            alert('Image uploaded successfully!');
        } catch (error) {
            console.error('Upload error:', error);
            alert('Failed to upload image.');
        }
    };

    const handleSubSubCategory = async (e) => {
        e.preventDefault();

        if (!subsubcategoryname.trim()) {
            message.error('Must enter sub subcategory name.');
            return;
        }

        if (!subcategory_id) {
            message.error('Must select sub category.');
            return;
        }

        setLoading(true);
        const subSubCategoryData = new FormData();
        subSubCategoryData.append('image', file);
        subSubCategoryData.append('name', subsubcategoryname);
        subSubCategoryData.append('subCategoryId', subcategory_id);

        try {
            await dispatch(createsubSubCategory(subSubCategoryData));
            setLoading(false);
            message.success('Sub subcategory created successfully.');
        } catch (error) {
            setLoading(false);
            message.error('Failed to create sub subcategory.');
        }
    };

    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>
            {loading ? <Loader /> : null}
            <div className="content container-fluid mt-5">
                <div>
                    <h2 className="h1 mb-0 d-flex gap-10">
                        <img
                            src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png"
                            alt=""
                        />
                        Sub Sub Category Setup
                    </h2>

                    <form>
                        <div className="card mt-2 p-4">
                            <div className="row">
                                <div className="col-12 form-group  lang_form" id="en-form">
                                    <label className="title-color" htmlFor="exampleFormControlInput1">
                                        Sub sub category Name<span className="text-danger">*</span>
                                        (EN)
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        className="form-control"
                                        placeholder="New Sub Sub Category"
                                        required=""
                                        value={subsubcategoryname}
                                        onChange={(e) => setSubsubcategoryname(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="title-color">
                                            Sub Category
                                            <span className="text-danger">*</span>
                                        </label>
                                        <select
                                            name="subCategoryId"
                                            id="subCategoryId"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => setSubcategoryId(e.target.value)}
                                        >
                                            <option value="" selected="" disabled="">
                                                Select main Sub Category
                                            </option>
                                            {subCategory?.length > 0 && subCategory.map((i, index) => (
                                                <option key={index} value={i._id}>{i.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="custom-file text-left">
                                    <label className="title-color">SubSubCategory Image</label>
                                    {file ? <img style={{ display: 'block' }} width={100} src={file} alt='' /> :
                                        <div>
                                            <img
                                                className="upload-img-view"
                                                id="viewer"
                                                src="https://6valley.6amtech.com/public/assets/back-end/img/900x400/img1.jpg"
                                                alt="image"
                                            />
                                        </div>}
                                </div>
                                <div style={{ marginTop: '190px' }} className="from_part_2">
                                    <div className="custom-file text-left">
                                        <input
                                            type="file"
                                            name="image"
                                            id="image"
                                            className="custom-file-input"
                                            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                            required=""
                                            onChange={(e) => handleUpload(e)}

                                        />
                                        <label className="custom-file-label" htmlFor="customFileEg1">
                                            Choose File
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                <button type="reset" id="reset" className="btn btn-secondary">
                                    Reset
                                </button>
                                <button onClick={(e) => handleSubSubCategory(e)} type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>

                        </div>
                    </form>

                    <div className="row mt-3" id="cate-table">
                        <div className="col-md-12">
                            <div className="card p-3">
                                <div className="px-3 py-4">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                                            <h5 className="text-capitalize d-flex gap-1">
                                                Sub Sub Category list
                                                <span className="badge badge-soft-dark radius-50 fz-12">{subSubCategory?.length}</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table
                                        id='allOrder'
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th>SL</th>
                                                <th>Sub Sub Category </th>
                                                <th>Sub Category</th>
                                                <th>Image</th>
                                                <th>Status</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subSubCategory?.length > 0 && subSubCategory.map((item, index) => (
                                                <tr key={item._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item?.subCategoryId?.name}</td>
                                                    <td><img className="rounded" width={64} src={item.image} alt="" /></td>
                                                    <td className="">
                                                        <div style={{
                                                            position: 'relative',
                                                            display: 'inline-block',
                                                            width: '40px',
                                                            height: '20px',
                                                        }}>
                                                            <input
                                                                type="checkbox"
                                                                checked={checkedStates[index]}
                                                                onChange={() => toggleSwitch(index, item._id, item.status)}
                                                                style={{
                                                                    opacity: 0,
                                                                    width: 0,
                                                                    height: 0,
                                                                }}
                                                            />
                                                            <div
                                                                onClick={() => toggleSwitch(index, item._id, item.status)}
                                                                style={{
                                                                    position: 'absolute',
                                                                    cursor: 'pointer',
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    backgroundColor: checkedStates[index] ? '#2196F3' : '#ccc',
                                                                    transition: '.4s',
                                                                    borderRadius: '20px',
                                                                }}
                                                            >
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    content: '""',
                                                                    height: '16px',
                                                                    width: '16px',
                                                                    left: checkedStates[index] ? '22px' : '2px',
                                                                    bottom: '2px',
                                                                    backgroundColor: 'white',
                                                                    transition: '.4s',
                                                                    borderRadius: '50%',
                                                                }}></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex justify-content-center gap-2">
                                                            <button
                                                                className=" btn btn-outline-success square-btn btn-sm mr-1"
                                                                onClick={() => navigate(`/UpdateSubSubCategorey/${item._id}`)}
                                                            >
                                                                <i className="bi bi-pencil" />
                                                            </button>
                                                            <button
                                                                onClick={() => onDelete(item._id)}
                                                                className=" btn btn-outline-danger square-btn btn-sm mr-1"
                                                                title="Delete"
                                                            >
                                                                <i className="bi bi-trash" />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SubSubCategory;
